/* eslint-disable no-useless-escape */
export const InputStyles = {
  TEXT: 'text',
  TEXTAREA: 'textarea',
  BOTH: 'both',
};

export const ValidationEnable = {
  ENABLED: 'enabled',
  DISABLED: 'disabled',
};

export const ValidationTypes = {
  EMAIL: 'email',
  WEBSITE: 'website',
  CANADIAN_POSTAL_CODE: 'canadianPostalCode',
  UK_POSTAL_CODE: 'ukPostCode',
  ISRAEL_POSTAL_CODE: 'israelPostalCode',
  US_ZIP_CODE: 'usZipCode',
  NORTH_AMERICAN_PHONE_NUMBER: 'northAmericanPhoneNumber',
  UK_PHONE_NUMBER: 'ukPhoneNumber',
  ISRAEL_PHONE_NUMBER: 'israelPhoneNumber',
  CUSTOM: 'custom',
  CHARACTER_COUNT: 'characterCount',
};

export const Validations = {
  [ValidationTypes.EMAIL]: {
    for: InputStyles.TEXT,
    label: 'engagement.validation_types.email',
    pattern: '^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$',
  },
  [ValidationTypes.WEBSITE]: {
    for: InputStyles.TEXT,
    label: 'engagement.validation_types.website',
    pattern:
      '[(http(s)?):\\/\\/(www\\.)?a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)',
  },
  [ValidationTypes.CANADIAN_POSTAL_CODE]: {
    for: InputStyles.TEXT,
    label: 'engagement.validation_types.canadian_postal_code',
    pattern: '^[ABCEGHJ-NPRSTVXY]\\d[ABCEGHJ-NPRSTV-Z][ -]?\\d[ABCEGHJ-NPRSTV-Z]\\d$',
  },
  [ValidationTypes.UK_POSTAL_CODE]: {
    for: InputStyles.TEXT,
    label: 'engagement.validation_types.uk_post_code',
    pattern: '^[A-Z]{1,2}[0-9R][0-9A-Z]? [0-9][ABD-HJLNP-UW-Z]{2}$',
  },
  [ValidationTypes.ISRAEL_POSTAL_CODE]: {
    for: InputStyles.TEXT,
    label: 'engagement.validation_types.israel_postal_code',
    pattern: '^\\d{5}(\\d{2})?$',
  },
  [ValidationTypes.US_ZIP_CODE]: {
    for: InputStyles.TEXT,
    label: 'engagement.validation_types.us_zip_code',
    pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
  },
  [ValidationTypes.NORTH_AMERICAN_PHONE_NUMBER]: {
    for: InputStyles.TEXT,
    label: 'engagement.validation_types.north_american_phone_number',
    pattern: '^\\(?([0-9]{3})\\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$',
  },
  [ValidationTypes.UK_PHONE_NUMBER]: {
    for: InputStyles.TEXT,
    label: 'engagement.validation_types.uk_phone_number',
    pattern:
      '^(((\\+44\\s?\\d{4}|\\(?0\\d{4}\\)?)\\s?\\d{3}\\s?\\d{3})|((\\+44\\s?\\d{3}|\\(?0\\d{3}\\)?)\\s?\\d{3}\\s?\\d{4})|((\\+44\\s?\\d{2}|\\(?0\\d{2}\\)?)\\s?\\d{4}\\s?\\d{4}))(\\s?\\#(\\d{4}|\\d{3}))?$',
  },
  [ValidationTypes.ISRAEL_PHONE_NUMBER]: {
    for: InputStyles.TEXT,
    label: 'engagement.validation_types.israel_phone_number',
    pattern:
      '^\\+?(972|0)(\\-)?0?(([23489]{1}\\d{7})|([71,72,73,74,75,76,77]{2}\\d{7})|[5]{1}\\d{8})$',
  },
  [ValidationTypes.CUSTOM]: {
    for: InputStyles.BOTH,
    label: 'engagement.validation_types.custom_regular_expression',
  },
  [ValidationTypes.CHARACTER_COUNT]: {
    for: InputStyles.BOTH,
    label: 'engagement.validation_types.character_count',
  },
};

export default Object.freeze({
  InputStyles,
  ValidationTypes,
  Validations,
});
